import {
  FaPhone,
  FaMailBulk,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaTiktok,
} from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import ISSUU from "../../assets/images/library/issuu.png";

const Contact = () => {
  const params = useParams();

  const contactBSA = [
    {
      nombre: "Daniela Albarrán Serrano",
      mail: "dalbarran@ucsc.cl",
      telefono: "+56 412345530",
      cargo: "Jefa de Biblioteca",
    },
  ];

  const contactBCH = [
    {
      nombre: "Alejandra Rivero Sepulveda",
      mail: "arivero@ucsc.cl",
      telefono: "+56 412437262",
      cargo: "Encargada de Biblioteca",
    },
  ];

  const contactITTA = [
    {
      nombre: "Mirta Luna Lavín",
      mail: "mluna@ucsc.cl",
      telefono: "+56 412345823",
      cargo: "Jefa de Biblioteca",
    },
  ];

  const contactITCH = [
    {
      nombre: "Silvana Villanelo Delgado",
      mail: "svillanelo@ucsc.cl",
      telefono: " +56 412345713",
      cargo: "Jefa de Biblioteca",
    },
  ];

  const contactITCA = [
    {
      nombre: "César Weggener Cortés",
      mail: "cesarw@ucsc.cl",
      telefono: "+56 412345775",
      cargo: "Jefe de Biblioteca",
    },
  ];

  const contactITLA = [
    {
      nombre: "Joselin Aguirre Peña",
      mail: "jaguirre@ucsc.cl",
      telefono: "(41) 2345758",
      cargo: "Asistente de Biblioteca",
    },
  ];

  const RssBSA = [
    {
      facebook: "https://www.facebook.com/bibliotecaucscsanandres",
      twitter: "https://twitter.com/SiB_UCSC",
      instagram: "https://www.instagram.com/bibliotecasucsc/",
      tiktok: "https://www.tiktok.com/@sibucsc",
      youtube: "https://www.youtube.com/channel/UCdesyDR2kvfadN-XFxXqhiQ",
    },
  ];

  const RssBSD = [
    {
      facebook: "https://www.facebook.com/bibliotecaucscsanandres",
      twitter: "https://twitter.com/SiB_UCSC",
      instagram: "https://www.instagram.com/sibucsc_bsa/",
      tiktok: "https://www.tiktok.com/@sibucsc",
      youtube: "https://www.youtube.com/channel/UCdesyDR2kvfadN-XFxXqhiQ",
    },
  ];

  const RssBCH = [
    {
      facebook: "https://www.facebook.com/BibliotecaUCSCCampusClinicoChillan",
      twitter: "https://twitter.com/SiBUCSC_BCH",
      instagram: "https://www.instagram.com/sibucsc_campusclinicochillan/",
      issuu: "https://issuu.com/bchucsc",
    },
  ];

  const RssITTA = [
    {
      facebook:
        "https://www.facebook.com/Biblioteca-UCSC-IT-Sede-Talcahuano-1159179454097764",
      instagram: "https://www.instagram.com/sibucsc_talcahuano/",
    },
  ];

  const RssITCH = [
    {
      facebook: "https://m.facebook.com/biblioteca.it.chillan",
      instagram: "https://www.instagram.com/biblioteca.it.chillan.ucsc/",
    },
  ];

  const RssITCA = [
    {
      facebook:
        "https://www.facebook.com/Biblioteca-UCSC-IT-Sede-Ca%C3%B1ete-945541048831593",
      instagram: "https://www.instagram.com/biblioteca.it.canete.ucsc/",
    },
  ];

  const RssITLA = [
    {
      facebook:
        "https://www.facebook.com/Biblioteca-UCSC-IT-Sede-Los-%C3%81ngeles-943483335664661",
      instagram: "https://www.instagram.com/biblioteca.it.losangeles.ucsc",
    },
  ];

  return (
    <Row className="m-1 m-xxl-5 ms-md-5 me-md-5">
      {params.abreviatura !== "BSD" && (
        <Col xl={6}>
          <div className="p-4 w-100 h-[225px] border bg-white shadow-lg sm:p-8">
            <div className="flex justify-between items-center m-1">
              <h5 className="text-xl font-bold leading-none text-[#646567]">
                Contacto
              </h5>
            </div>
            <div className="flow-root">
              <ul role="list" className="divide-y divide-gray-200">
                <li className="py-1 sm:py-4">
                  <div className="flex items-center space-x-3">
                    <div className="flex-1 min-w-0">
                      {(() => {
                        if (params.abreviatura == "BSA") {
                          return contactBSA.map((dat, i) => (
                            <div key={i}>
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {dat.nombre}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaMailBulk className="m-1" />
                                {dat.mail}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaPhone className="m-1" />
                                {dat.telefono}
                              </p>
                              <p className="text-sm text-gray-500 font-bold truncate">
                                {dat.cargo}
                              </p>
                            </div>
                          ));
                        } else if (params.abreviatura == "BCH") {
                          return contactBCH.map((dat, i) => (
                            <div key={i}>
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {dat.nombre}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaMailBulk className="m-1" />
                                {dat.mail}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaPhone className="m-1" />
                                {dat.telefono}
                              </p>
                              <p className="text-sm text-gray-500 font-bold  truncate">
                                {dat.cargo}
                              </p>
                            </div>
                          ));
                        } else if (params.abreviatura == "ITTA") {
                          return contactITTA.map((dat, i) => (
                            <div key={i}>
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {dat.nombre}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaMailBulk className="m-1" />
                                {dat.mail}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaPhone className="m-1" />
                                {dat.telefono}
                              </p>
                              <p className="text-sm text-gray-500 font-bold  truncate">
                                {dat.cargo}
                              </p>
                            </div>
                          ));
                        } else if (params.abreviatura == "ITCH") {
                          return contactITCH.map((dat, i) => (
                            <div key={i}>
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {dat.nombre}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaMailBulk className="m-1" />
                                {dat.mail}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaPhone className="m-1" />
                                {dat.telefono}
                              </p>
                              <p className="text-sm text-gray-500 font-bold  truncate">
                                {dat.cargo}
                              </p>
                            </div>
                          ));
                        } else if (params.abreviatura == "ITCA") {
                          return contactITCA.map((dat, i) => (
                            <div key={i}>
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {dat.nombre}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaMailBulk className="m-1" />
                                {dat.mail}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaPhone className="m-1" />
                                {dat.telefono}
                              </p>
                              <p className="text-sm text-gray-500 font-bold  truncate">
                                {dat.cargo}
                              </p>
                            </div>
                          ));
                        } else if (params.abreviatura == "ITLA") {
                          return contactITLA.map((dat, i) => (
                            <div key={i}>
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {dat.nombre}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaMailBulk className="m-1" />
                                {dat.mail}
                              </p>
                              <p className="flex text-sm text-gray-500 truncate">
                                <FaPhone className="m-1" />
                                {dat.telefono}
                              </p>
                              <p className="text-sm text-gray-500 font-bold  truncate">
                                {dat.cargo}
                              </p>
                            </div>
                          ));
                        }
                      })()}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      )}
      {/* DIV CONTACTOS SANTO DOMINGO */}
      {params.abreviatura == "BSD" && (
        <>
          <div className="flex justify-between items-center m-1">
            <h5 className="text-xl font-bold leading-none text-[#646567]">
              Contactos
            </h5>
          </div>
          <Col xl={4} sm={12}>
            <div className="p-4 w-100 h-[225px] border bg-white shadow-lg sm:p-8">
              <div className="flow-root">
                <ul role="list" className="divide-y divide-gray-200">
                  <li className="py-1 sm:py-4 pt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          Fernando Flores M.
                        </p>
                        <p className="flex text-sm text-gray-500 truncate">
                          <FaMailBulk className="m-1" />
                          fflores@ucsc.cl
                        </p>
                        <p className="flex text-sm text-gray-500 truncate">
                          <FaPhone className="m-1" />
                          +56 412345677
                        </p>
                        <p className="text-sm text-gray-500 font-bold  truncate">
                          Jefe de Biblioteca
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xl={4} lg={12}>
            <div className="p-4 w-100 h-[225px] border bg-white shadow-lg sm:p-8">
              <div className="flow-root">
                <ul role="list" className="divide-y divide-gray-200">
                  <li className="py-1 sm:py-4 pt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          Francisco Sandoval.
                        </p>
                        <p className="flex text-sm text-gray-500 truncate">
                          <FaMailBulk className="m-1" />
                          fsandoval@ucsc.cl
                        </p>
                        <p className="flex text-sm text-gray-500 truncate">
                          <FaPhone className="m-1" />
                          +56412345675
                        </p>
                        <p className="text-sm text-gray-500 font-bold  truncate">
                          Asistente de Biblioteca
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xl={4} lg={12}>
            <div className="p-4 w-100 h-[225px] border bg-white shadow-lg sm:p-8">
              <div className="flow-root">
                <ul role="list" className="divide-y divide-gray-200">
                  <li className="py-1 sm:py-4 pt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          Rafael Pardo M.
                        </p>
                        <p className="flex text-sm text-gray-500 truncate">
                          <FaMailBulk className="m-1" />
                          rapardo@ucsc.cl
                        </p>
                        <p className="flex text-sm text-gray-500 truncate">
                          <FaPhone className="m-1" />
                          +56412345675
                        </p>
                        <p className="text-sm text-gray-500 font-bold  truncate">
                          Asistente de Biblioteca
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </>
      )}
      {/* CIERRE DIV SANTO DOMINGO */}
      <Col xl={6}>
        {params.abreviatura !== "BSD" && (
          <div className="p-4 border shadow-lg h-[225px] sm:p-8 w-100">
            <div className="flex justify-between items-center">
              {params.abreviatura !== "BSD" && (
                <h5 className="text-xl font-bold leading-none text-[#646567]">
                  ¡Síguenos en Redes Sociales!
                </h5>
              )}
            </div>
            <div className="flow-root h-[147px] w-100">
              <ul>
                <li>
                  <div className=" flow-root wrapper mx-auto mt-3 pt-4 col d-flex justify-content-center">
                    {(() => {
                      if (params.abreviatura == "BSA") {
                        return RssBSA.map((dat, i) => (
                          <span key={i} className="d-flex inline">
                            <a href={dat.facebook} target="__blank">
                              <FaFacebook size={60} className="iconRss-fb" />
                            </a>
                            <a href={dat.instagram} target="__blank">
                              <FaInstagram
                                size={60}
                                className="iconRss-insta"
                              />
                            </a>
                            <a
                              href={dat.twitter}
                              className="-mt-2"
                              target="__blank"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="60"
                                width="60"
                                viewBox="0 0 512 512"
                                className="inline iconRss"
                              >
                                <path
                                  fill="#000"
                                  d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                                />
                              </svg>
                            </a>
                            {/*  <a href={dat.tiktok} target="__blank">
                              <FaTiktok size={50} className="iconRss-tw" />
                            </a> */}
                          </span>
                        ));
                      } else if (params.abreviatura == "BSD") {
                        return RssBSD.map((dat, i) => <></>);
                      } else if (params.abreviatura == "BCH") {
                        return RssBCH.map((dat, i) => (
                          <span key={i} className="d-flex inline">
                            <a href={dat.facebook} target="__blank">
                              <FaFacebook size={60} className="iconRss-fb" />
                            </a>
                            <a href={dat.instagram} target="__blank">
                              <FaInstagram
                                size={60}
                                className="iconRss-insta"
                              />
                            </a>
                            <a
                              href={dat.twitter}
                              target="__blank"
                              className="-mt-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="60"
                                width="60"
                                viewBox="0 0 512 512"
                                className="inline iconRss"
                              >
                                <path
                                  fill="#000"
                                  d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                                />
                              </svg>
                            </a>
                            <a href={dat.issuu} target="__blank">
                              <img
                                src={ISSUU}
                                height={60}
                                width={60}
                                className="iconRss-tw"
                              />
                            </a>
                          </span>
                        ));
                      } else if (params.abreviatura == "ITTA") {
                        return RssITTA.map((dat, i) => (
                          <span key={i} className="d-flex inline">
                            <a href={dat.facebook} target="__blank">
                              <FaFacebook size={60} className="iconRss-fb" />
                            </a>
                            <a href={dat.instagram} target="__blank">
                              <FaInstagram
                                size={60}
                                className="iconRss-insta"
                              />
                            </a>
                          </span>
                        ));
                      } else if (params.abreviatura == "ITCH") {
                        return RssITCH.map((dat, i) => (
                          <span key={i} className="d-flex inline">
                            <a href={dat.facebook} target="__blank">
                              <FaFacebook size={60} className="iconRss-fb" />
                            </a>
                            <a href={dat.instagram} target="__blank">
                              <FaInstagram
                                size={60}
                                className="iconRss-insta"
                              />
                            </a>
                          </span>
                        ));
                      } else if (params.abreviatura == "ITCA") {
                        return RssITCA.map((dat, i) => (
                          <span key={i} className="d-flex inline">
                            <a href={dat.facebook} target="__blank">
                              <FaFacebook size={60} className="iconRss-fb" />
                            </a>
                            <a href={dat.instagram} target="__blank">
                              <FaInstagram
                                size={60}
                                className="iconRss-insta"
                              />
                            </a>
                          </span>
                        ));
                      } else if (params.abreviatura == "ITLA") {
                        return RssITLA.map((dat, i) => (
                          <span key={i} className="d-flex inline">
                            <a href={dat.facebook} target="__blank">
                              <FaFacebook size={60} className="iconRss-fb" />
                            </a>
                            <a href={dat.instagram} target="__blank">
                              <FaInstagram
                                size={60}
                                className="iconRss-insta"
                              />
                            </a>
                          </span>
                        ));
                      }
                    })()}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};
export default Contact;
