import React, { useState } from "react";
import "../../css/CardEds.css";
import { useMatchMedia } from "../../useMatchMedia";
import { FaUser } from "react-icons/fa";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const CardEds = () => {
  const isDesktopResolution = useMatchMedia("(max-width:1054px)", true);
  const [resourceType, setResourceType] = useState("");
  const [option, setOption] = useState("title");
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState("TI");
  const [searchEDS, setSearchEDS] = useState("");

  //ESTADOS PARA BUSCADOR EDS
  //ESTADO PARA SSO O GUEST //
  const [isChecked, setIsChecked] = useState(false);
  const [typeAccess, setTypeAccess] = useState("guest");
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setTypeAccess("sso");
    } else {
      setTypeAccess("guest");
    }
  };
  //CIERRE FUNCIONES SSO O GUEST

  // Función para manejar los cambios en el <select> EDS
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Handle change valor input query EDS
  const handleEDS = (e) => {
    setSearchEDS(e.target.value);
  };

  //estados para LOCATE
  const handleResourceTypeChange = (e) => setResourceType(e.target.value);
  const handleOptionChange = (e) => setOption(e.target.value);
  const handleQueryChange = (e) => setQuery(e.target.value);

  return (
    <div className="row justify-content-md-center">
      <div className="col-lg-7 col-md-7">
        <div className="card" id="cardEds">
          <div className="card-header">
            <h5 className="text-light font-bold">Buscador</h5>
          </div>
          <Tabs defaultActiveKey="bib" id="tabs-recursos" className="mb-3">
            <Tab
              eventKey="bib"
              title="Búsqueda integrada de material físico y digital"
            >
              <form
                action="https://research.ebsco.com/c/6gwsse/results"
                target="_blank"
                method="GET"
              >
                <input
                  type="hidden"
                  name="acr_values"
                  value={typeAccess}
                  onChange={(e) => setTypeAccess(e.target.value)}
                />
                <input type="hidden" name="autocorrect" value="y" />

                <input type="hidden" name="auth_type" value="custom" />

                <div className="eb-search-container eb-search-container__has-select">
                  <div className="eb-search">
                    <div className="eb-search__box">
                      <div className="eb-search__search-prefix">
                        <select
                          className="eb-search__select"
                          value={selectedValue}
                          onChange={handleSelectChange}
                        >
                          <option value="TI">Título</option>
                          <option value="AU">Autor</option>
                          <option value="">Palabra clave</option>
                        </select>
                      </div>

                      <input
                        type="hidden"
                        name="q"
                        value={
                          selectedValue === ""
                            ? `${searchEDS}`
                            : `${selectedValue} ${searchEDS}`
                        }
                      />

                      <input
                        className="eb-search__input-bquery"
                        value={searchEDS}
                        onChange={handleEDS}
                        type="search"
                        id="searchInput"
                        placeholder="Accede a todas las colecciones disponibles"
                      />
                      <button
                        className="eb-search__submit-button"
                        type="submit"
                      >
                        <div className="eb-search__submit-text">Búsqueda</div>
                      </button>
                      {isDesktopResolution && (
                        <>
                          <br></br>
                          <a
                            href="https://research.ebsco.com/c/6gwsse/search/advanced/filters?acr_values=guest"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="enlaceAdv mt-4 float-right text-[#d12421] font-bold"
                          >
                            Búsqueda avanzada
                          </a>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-xl-12">
                      <div className="responsive-options">
                        <div className="col d-flex"></div>

                        <div
                          className="ebsco-search-box__extras__limiters__limiter"
                          style={{ display: "block" }}
                        >
                          {isDesktopResolution === false && (
                            <>
                              <a
                                href="https://research.ebsco.com/c/6gwsse/search/advanced/filters?acr_values=guest"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="enlaceAdvance mt-1 float-right text-[#d12421] font-bold"
                              >
                                Búsqueda avanzada
                              </a>
                            </>
                          )}
                        </div>

                        <div className="ebsco-search-box__extras__limiters mt-2">
                          <div
                            className="ebsco-search-box__extras__limiters__limiter"
                            style={{ display: "block" }}
                          >
                            <input
                              type="checkbox"
                              className="check__eds"
                              id="FT"
                              name="limiters"
                              value="FT:Y"
                            />
                            <label htmlFor="FT" className="font-bold">
                              Texto completo
                            </label>
                          </div>
                          <div
                            className="ebsco-search-box__extras__limiters__limiter"
                            style={{ display: "block" }}
                          >
                            <input
                              type="checkbox"
                              className="check__eds"
                              id="FC"
                              name="limiters"
                              value="FC:Y"
                            />
                            <label htmlFor="FC" className="font-bold">
                              Sólo material de biblioteca
                            </label>
                          </div>
                          <div
                            className="ebsco-search-box__extras__limiters__limiter"
                            style={{ display: "block" }}
                          >
                            <input
                              type="checkbox"
                              className="check__eds"
                              id="RV"
                              name="limiters"
                              value="RV:Y"
                            />
                            <label htmlFor="RV1" className="font-bold">
                              Revistas académicas arbitradas
                            </label>
                          </div>
                        </div>
                        <div
                          className="ebsco-search-box__extras__limiters__limiter mt-lg-4"
                          style={{ display: "block" }}
                        >
                          <input
                            type="checkbox"
                            className="check__eds"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <label className="font-bold">
                            Accede con tu cuenta OpenAthens
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="footerEDS">
                  <p>
                    Para consultar tus préstamos haz click{" "}
                    <a
                      className="text-[#d12421] font-bold"
                      href="https://ucsc.locate.ebsco.com/login?redirect=%2Fholds-and-checkouts%2Fcheckouts"
                      target="__blank"
                    >
                      aquí
                    </a>
                  </p>
                </footer>
              </form>
            </Tab>
            <Tab eventKey="rec" title="Catálogo UCSC">
              <form
                action="https://ucsc.locate.ebsco.com/search"
                target="_blank"
                className="ebsco-single-search"
              >
                <div className="eb-search-container eb-search-container__has-select">
                  <div className="eb-search">
                    <div className="eb-search__box">
                      <div className="eb-search__search-prefix">
                        <select
                          className="eb-search__select"
                          name="option"
                          value={option}
                          onChange={handleOptionChange}
                        >
                          <option value="author">Autor</option>
                          <option value="isbn">ISBN</option>
                          <option value="issn">ISSN</option>
                          <option value="keyword">Palabra clave</option>
                          <option value="subject">Materia</option>
                          <option value="title">Título</option>
                        </select>
                      </div>
                      <input name="facets" value={resourceType} type="hidden" />
                      <label
                        className="eb-sr-only"
                        htmlFor="eb-sbb-search-input"
                      >
                        Buscar el catálogo de la biblioteca
                      </label>
                      <input
                        className="eb-search__input-bquery"
                        id="eb-sbb-search-input"
                        name="query"
                        type="text"
                        placeholder="Búsqueda de material bibliográfico en biblioteca"
                        value={query}
                        onChange={handleQueryChange}
                      />
                      <button
                        className="eb-search__submit-button"
                        type="submit"
                      >
                        <div className="eb-search__submit-text">Búsqueda</div>
                      </button>
                    </div>

                    <div className="row">
                      <div className="col d-flex justify-content-center">
                        <fieldset className="">
                          <label className="ms-xl-2 mt-4 eb-search__label eb-search__radio_label">
                            <input
                              type="radio"
                              name=""
                              className="eb-search__radio_input eb-search__hidden_input"
                              value=""
                              checked={resourceType === ""}
                              onChange={handleResourceTypeChange}
                            />
                            <span className="eb-search__radio_label_text eb-search__boxed_label font-bold">
                              Todo el material
                            </span>
                          </label>
                          <label className="ms-xl-2 eb-search__label eb-search__radio_label">
                            <input
                              type="radio"
                              name=""
                              className="ms-xl-2 eb-search__radio_input eb-search__hidden_input"
                              value="sourceTypes[]=LIBRO"
                              checked={resourceType === "sourceTypes[]=LIBRO"}
                              onChange={handleResourceTypeChange}
                            />
                            <span className="eb-search__radio_label_text eb-search__boxed_label font-bold">
                              Libro
                            </span>
                          </label>
                          <label className="eb-search__label eb-search__radio_label">
                            <input
                              type="radio"
                              name=""
                              className="ms-xl-2 eb-search__radio_input eb-search__hidden_input"
                              value="sourceTypes[]=TESIS"
                              checked={resourceType === "sourceTypes[]=TESIS"}
                              onChange={handleResourceTypeChange}
                            />
                            <span className="eb-search__radio_label_text eb-search__boxed_label font-bold">
                              Tesis
                            </span>
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="footerEDS mt-3">
                  <p>
                    Para consultar tus préstamos haz click{" "}
                    <a
                      className="text-[#d12421] font-bold"
                      href="https://ucsc.locate.ebsco.com/login?redirect=%2Fholds-and-checkouts%2Fcheckouts"
                      target="__blank"
                    >
                      aquí
                    </a>
                  </p>
                </footer>
              </form>
            </Tab>
          </Tabs>
        </div>
        <br></br>
      </div>
    </div>
  );
};

export default CardEds;
