import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Col";
import CardEds from "../Components/HomeComponents/CardEds";
import Announ from "../Components/HomeComponents/Announ";
import Information from "../Components/HomeComponents/Information";
import Regulations from "../Components/HomeComponents/Regulations";
import Repo from "../Components/HomeComponents/Repo";
import RepoInv from "../Components/HomeComponents/RepoInv";
import Journal from "../Components/HomeComponents/Journal";
import BooksDigital from "../Components/HomeComponents/BooksDigital";
import Events from "../Components/HomeComponents/Events";
import "react-slideshow-image/dist/styles.css";
import { Zoom } from "react-slideshow-image";
import { useMatchMedia } from "../useMatchMedia";
import logoSIBUCSC from "../assets/images/home/navbar/SIBUCSC.png";
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";
import Newsletters from "./HomeComponents/Newsletters";
import Swal from "sweetalert2";
import "../css/SwalHome.css";
import { FaBell } from "react-icons/fa";

const TOAST_PROPERTIES = [
  {
    id: 1,
    title: "Revisa nuestros Videos Tutoriales",
    description: "Para visualizar nuestros videos",
  },
];

const Toast = ({ toast, onClose }) => {
  return (
    <div
      className="toast2"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 1000,
        backgroundColor: "#646567",
      }}
    >
      <div>
        <FaBell size={25} className="d-inline iconToast" />
        <h5>{toast.title}</h5>
        <p>
          {toast.description}{" "}
          <a
            href="https://www.youtube.com/@sib_ucsc"
            className="enlaceToast"
            target="__blank"
          >
            <strong>Ingresa aquí</strong>
          </a>{" "}
        </p>
      </div>
      <button className="close" onClick={onClose}>
        <span className="font-bold">X</span>
      </button>
    </div>
  );
};

export const Home = () => {
  const isDesktopResolution = useMatchMedia("(max-width:520px)", true);
  const [toasts, setToasts] = useState(TOAST_PROPERTIES);

  const handleClose = (id) => {
    setToasts(toasts.filter((toast) => toast.id !== id));
  };

  const images = [
    'url("https://sibucsc.cl/static/media/banner_biblioteca_01.3a9ba02657a0daff9b7e.png")',
    'url("https://sibucsc.cl/static/media/banner_biblioteca_02.bf39010f50c234569b2a.png")',
    'url("https://sibucsc.cl/static/media/banner_biblioteca_03.75fe1fb5d4747bf3ea95.png")',
    'url("https://sibucsc.cl/static/media/banner_biblioteca_04.6709e6bb8140fc9b215a.png")',
    'url("https://sibucsc.cl/static/media/banner_biblioteca_05.e91d174aae9ff7f32b31.png")',
  ];

  const randomImage = images[Math.floor(Math.random() * images.length)];

  useEffect(() => {
    document.getElementById("bannerSibucsc").style.backgroundImage =
      randomImage;
  }, []);

  return (
    <>
      <Container className="web-container">
        <Row>
          <div id="bannerSibucsc">
            <CardEds />
            <Zoom
              arrows={false}
              duration={0}
              transitionDuration={0}
              infinite={false}
              className="bg-[#f9f9f9]"
            >
              {images.map((bib, index) => (
                <div
                  key={index}
                  className="bannerHome bg-[#f9f9f9]"
                />
              ))}
            </Zoom>
          </div>
        </Row>
      </Container>
      <div className="container-fluid">
        <Row className="bg-[#F2F2F2] -mt-5">
          <Col lg={6} md={12} xs={12}>
            {isDesktopResolution && (
              <div className="col d-flex justify-content-center">
                <img
                  src={logoSIBUCSC}
                  height={50}
                  width={200}
                  className="mt-2"
                />
              </div>
            )}

            {isDesktopResolution && (
              <>
                <br></br>
                <CardEds />
              </>
            )}
          </Col>
        </Row>
        <Container className="web-container w-100">
          <Row className="rowRepositories pt-3">
            <div className="d-flex justify-content-center mt-5">
              <span className="font-bold titleInfoRepos">
                Plataformas y Repositorios Académicos
              </span>
            </div>
            <Col lg={3} md={6} xs={6} className="p-3 mt-4">
              <Repo />
            </Col>
            <Col lg={3} md={6} xs={6} className="p-3 mt-4">
              <RepoInv />
            </Col>
            <Col lg={3} md={6} xs={6} className="p-3 mt-4">
              <BooksDigital />
            </Col>
            <Col lg={3} md={6} xs={6} className="p-3 mt-4">
              <Journal />
            </Col>
          </Row>
        </Container>
        <Container className="web-container">
          <Row>
            <Col lg={6} md={12} xs={12} className="mt-5">
              <Events />
            </Col>
            <Col lg={6} md={12} xs={12} className="mt-5">
              <Newsletters />
            </Col>
          </Row>
        </Container>
        <Container className="web-container">
          <Row>
            <Col lg={12} md={12} xs={12} className="mt-3">
              {" "}
              <Announ />
            </Col>
          </Row>
        </Container>
        <Container className="web-container">
          <Row>
            <Col lg={12} md={12} xs={12} className="bgInformation pt-5 mt-1">
              {" "}
              <Information />
              <br></br>
            </Col>
          </Row>
        </Container>
        <Container className="web-container">
          <Row>
            <Col lg={12} md={12} xs={12} className="">
              {" "}
              <Regulations />
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            toast={toast}
            onClose={() => handleClose(toast.id)}
          />
        ))}
      </div>
    </>
  );
};
